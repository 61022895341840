import { Autocomplete, Checkbox, FormControlLabel, Grid, Stack, TextField } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import { renderAutocompleteCellDisplay } from '../../../../../utils/components/CrudDataGrid/AutocompleteCellWithTextField';
import { renderAutocompleteEditCellWithTextField } from '../../../../../utils/components/CrudDataGrid/AutocompleteEditCellWithTextField';
import DialogCrudDataGrid, {
  DialogMode,
  getDateValue,
  handleAutocompleteChange,
  handleCheckboxChange,
  handleDateChange,
  handleNumericTextFieldChange,
  handleTextFieldChange,
} from '../../../../../utils/components/DialogCrudDataGrid';
import NumericTextField from '../../../../../utils/components/NumericTextField';
import { PropertyInventoryDataGrid } from './PropertyInventoryDataGrid';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ActionList } from '../../../../../components/CaseView/CaseViewDateTimeField';

// export interface PropertyEvidenceColumn extends GridColDef {
//   // flex: number;
//   // minWidth: number;
//   // field: string;
//   // headerName: string;
//   // type?: string;
//   // headerAlign?: 'left' | 'center' | 'right';
//   // align?: 'left' | 'center' | 'right';
//   // valueFormatter?: (value: Option) => string | null;
//   // valueOptions?: Option[];
//   // renderEditCell?: (params: any) => JSX.Element;
//   // renderCell?: (params: any) => JSX.Element;
//   toggleTextfieldoption?: string;
// }

export type PropertyEvidenceColumn = GridColDef & {
  toggleTextfieldoption?: string;
};

import * as Yup from 'yup';


interface NewRow {
  [key: string]: any; // Replace with a more specific type if available
}

interface UpdatedRow {
  [key: string]: any; // Replace with a more specific type if available
}

export function EvidenceDataGrid() {
  const [evidenceOptions, setEvidenceOptions] = useState([]);
  const [custodianOptions, setCustodianOptions] = useState([]);
  const { VITE_API_URL, VITE_FASTAPI_URL } = import.meta.env;
  const formik = useFormikContext<CMSCase>();
  const [evidenceCOC, setEvidenceCOC] = useState([]);
  const { user } = useAuth();
  const [selectedRow, setSelectedRow] = useState<any>(null);

  useEffect(() => {
    fetch(VITE_API_URL + 'getevidencetype', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setEvidenceOptions(data);
      })
      .catch(e => {
        console.error(`Failed to get from evidence Options\nError: ${e}`);
      });

    fetch(VITE_API_URL + 'getcustodianoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setCustodianOptions(data);
      })
      .catch(e => {
        console.error(`Failed to get from custodian options\nError: ${e}`);
      });

    fetchEvidenceCOC();
  }, []);

  const fetchEvidenceCOC = async () => {
    await fetch(`${VITE_FASTAPI_URL}getevidencecoclog?evidenceSeq=${selectedRow?.evidenceSeq}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    })
      .then(res => {
        if (res.status === 204) {
          return;
        }
        return res.json();
      })
      .then(data => {
        setEvidenceCOC(data);
      })
      .catch(e => {
        console.error(e);
      });
  };

  const createEvidenceCOC = async (newRow: NewRow): Promise<void> => {
    console.log('newRow', newRow);

    let formData = new FormData();
    formData.append('newRow', JSON.stringify(newRow));
    formData.append('evidenceSeq', selectedRow?.evidenceSeq);

    await fetch(VITE_FASTAPI_URL + 'createevidencecoclog', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occurred');
        }
        return res.text();
      })
      .catch(e => {
        console.error(e);
      });

    await fetchEvidenceCOC();
  };

  const updateEvidenceCOC = async (updatedRow: UpdatedRow): Promise<void> => {
    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedRow));

    await fetch(VITE_FASTAPI_URL + 'updateevidencecoclog', {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.error(e);
      });

    await fetchEvidenceCOC();
  };

  const deleteEvidenceCOC = async (id: string | number): Promise<void> => {
    await fetch(`${VITE_FASTAPI_URL}deleteevidencecoclog/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    })
      .then((res: Response) => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch((e: Error) => {
        console.error(e);
      });

    await fetchEvidenceCOC();
  };

  const propertyEvidenceColumns: PropertyEvidenceColumn[] = [
    {
      flex: 1,
      minWidth: 150,
      field: 'evidenceItem',
      headerName: 'Evidence Item',
      type: 'singleSelect',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (value: Option) => {
        if (!value) return value as unknown as string;
        return value.optionName;
      },
      valueOptions: evidenceOptions,
      renderEditCell: params => {
        const textFieldText = params.row.otherEvidence;
        return renderAutocompleteEditCellWithTextField({
          ...params,
          textFieldText,
          rowField: 'otherEvidence',
        });
      },
      renderCell: params => {
        const textFieldText = params.row.otherEvidence;
        return renderAutocompleteCellDisplay({
          ...params,
          textFieldText,
        });
      },
      toggleTextfieldoption: 'Other',
    },
    {
      flex: 0.5,
      minWidth: 120,
      field: 'number',
      headerName: 'Number',
      headerAlign: 'left',
      align: 'left',
      type: 'number',
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'description',
      headerName: 'Description',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
    },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'funeralHome',
      headerName: 'FH',
      type: 'boolean',
      renderCell: ({ value }: GridRenderCellParams<any, any, any, any>) => (
        <Checkbox size="small" disabled checked={!!value} />
      )
    },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'releasedtoLE',
      headerName: 'LE',
      type: 'boolean',
      renderCell: ({ value }: GridRenderCellParams<any, any, any, any>) => (
        <Checkbox size="small" disabled checked={!!value} />
      )
    },
    {
      flex: 0.3,
      minWidth: 80,
      field: 'retained',
      headerName: 'RET',
      type: 'boolean',
      renderCell: ({ value }: GridRenderCellParams<any, any, any, any>) => (
        <Checkbox size="small" disabled checked={!!value} />
      )
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'note',
      headerName: 'Notes',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
    },
  ];

  const chainOfCustodyColumns: GridColDef[] = [
    {
      flex: 1,
      minWidth: 120,
      field: 'custodyDateTime',
      headerName: 'Date/Time',
      headerAlign: 'left',
      align: 'left',
      type: 'dateTime',
      valueGetter: (value) => {
        if (!value) return null;
        return new Date(value);
      }
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'evidenceItem',
      headerName: 'Evidence Item',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      valueFormatter: (value: Option) => {
        if (!value) return value as unknown as string;
        return value.optionName;
      }
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'previousCustodian',
      headerName: 'Previous Custodian',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      valueFormatter: (value: Option) => {
        if (!value) return value as unknown as string;
        return value.optionName;
      }
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'newCustodian',
      headerName: 'New Custodian',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      valueFormatter: (value: Option) => {
        if (!value) return value as unknown as string;
        return value.optionName;
      }
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'releasedBy',
      headerName: 'Released By',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'receivedBy',
      headerName: 'Received By',
      headerAlign: 'left',
      align: 'left',
      type: 'string',
    },
  ];


  const evidenceCOCLogSchema = Yup.object().shape({
    // Ensure it's a valid date and required
    custodyDateTime: Yup.date()
      .typeError('Invalid or empty Date/Time')
      .required('Date/Time is required'),

    // Other fields optional, or customize as needed
    previousCustodian: Yup.object().nullable(),
    newCustodian: Yup.object().nullable(),
    releasedBy: Yup.string().nullable(),
    receivedBy: Yup.string().nullable(),
  });


  const evidenceCOCLogDialogContent = ({
    mode,
    data,
    onChange,
  }: {
    mode: 'add' | 'edit';
    data: any;
    onChange: (data: any) => void;
  }) => {
    const [localData, setLocalData] = useState<any | null>(null);

    useEffect(() => {
      setLocalData(data);
    }, [data]);

    return (
      <Grid container spacing={1} sx={{ alignItems: 'center' }}>
        <Grid item xs={12} md={6} xl={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label='Date/Time'
              value={getDateValue(localData?.custodyDateTime)}
              onChange={value =>
                handleDateChange(value, 'custodyDateTime', localData, setLocalData, onChange)
              }
              slots={{ actionBar: ActionList }}
              sx={{ width: '100%' }}
              slotProps={{ textField: { margin: 'dense' } }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <TextField
            value={selectedRow?.evidenceItem?.optionName ?? ''}
            label='Evidence Item'
            fullWidth
            margin='dense'
            disabled
            sx={{ backgroundColor: '#00000044', borderRadius: '4px' }}
            required
          />
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <Autocomplete
            options={custodianOptions || []}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.previousCustodian || null}
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, 'previousCustodian', localData, setLocalData, onChange)
            }
            renderInput={params => (
              <TextField {...params} label='Previous Custodian' margin='dense' fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <Autocomplete
            options={custodianOptions || []}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.newCustodian || null}
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, 'newCustodian', localData, setLocalData, onChange)
            }
            renderInput={params => (
              <TextField {...params} label='New Custodian' margin='dense' fullWidth />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <TextField
            value={localData?.releasedBy ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'releasedBy', localData, setLocalData, onChange)
            }
            label='Released By'
            fullWidth
            margin='dense'
          />
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <TextField
            value={localData?.receivedBy ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'receivedBy', localData, setLocalData, onChange)
            }
            label='Received By'
            fullWidth
            margin='dense'
          />
        </Grid>
      </Grid>
    );
  };

  const evidenceDataGridDialogContent = ({
    mode,
    data,
    onChange,
  }: {
    mode: DialogMode;
    data: Record<string, any>;
    onChange: (data: Record<string, any>) => void;
  }) => {
    const [localData, setLocalData] = useState<Record<string, any> | null>(null);

    useEffect(() => {
      setLocalData(data);
    }, [data]);

    return (
      <Grid container spacing={1} sx={{ alignItems: 'center' }}>
        <Grid item xs={12} md={6} xl={3}>
          <Autocomplete
            options={evidenceOptions || []}
            getOptionLabel={option => option.optionName || ''}
            value={localData?.evidenceItem || null}
            onChange={(event, value) =>
              handleAutocompleteChange(
                event,
                value,
                'evidenceItem',
                localData,
                setLocalData,
                onChange
              )
            }
            renderInput={params => (
              <TextField {...params} label='Evidence Item' margin='dense' fullWidth />
            )}
          />
        </Grid>

        {localData?.evidenceItem?.optionName === 'Other' && (
          <Grid item xs={12} md={6} xl={3}>
            <TextField
              value={localData?.otherEvidence ?? ''}
              onChange={event =>
                handleTextFieldChange(event, 'otherEvidence', localData, setLocalData, onChange)
              }
              label='Other Evidence Name'
              fullWidth
              margin='dense'
            />
          </Grid>
        )}

        <Grid item xs={12} md={6} xl={3}>
          <NumericTextField
            value={localData?.number ?? 0}
            onChange={value =>
              handleNumericTextFieldChange(
                Number(value),
                'number',
                localData,
                setLocalData,
                onChange
              )
            }
            margin='dense'
            fullWidth
            label='Number'
          />
        </Grid>

        <Grid item xs={12} md={6} xl={3}>
          <TextField
            value={localData?.description ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'description', localData, setLocalData, onChange)
            }
            label='Description'
            fullWidth
            margin='dense'
          />
        </Grid>

        <Grid item xs='auto'>
          <FormControlLabel
            label='Funeral Home'
            control={
              <Checkbox
                checked={localData?.funeralHome ?? false}
                onChange={event =>
                  handleCheckboxChange(event, 'funeralHome', localData, setLocalData, onChange)
                }
              />
            }
          />
        </Grid>

        <Grid item xs='auto'>
          <FormControlLabel
            label='Law Enforcement'
            control={
              <Checkbox
                checked={localData?.releasedtoLE ?? false}
                onChange={event =>
                  handleCheckboxChange(event, 'releasedtoLE', localData, setLocalData, onChange)
                }
              />
            }
          />
        </Grid>

        <Grid item xs='auto'>
          <FormControlLabel
            label='Retained'
            control={
              <Checkbox
                checked={localData?.retained ?? false}
                onChange={event =>
                  handleCheckboxChange(event, 'retained', localData, setLocalData, onChange)
                }
              />
            }
            sx={{ pr: 2 }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={localData?.note ?? ''}
            onChange={event =>
              handleTextFieldChange(event, 'note', localData, setLocalData, onChange)
            }
            label='Notes'
            fullWidth
            margin='dense'
          />
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (selectedRow)
      fetchEvidenceCOC();
  }, [selectedRow]);

  return (
    <Stack spacing={2}>
      <PropertyInventoryDataGrid
        rows={formik.values?.caseMortuary?.propertyInventory?.propertyEvidence ?? []}
        columns={propertyEvidenceColumns}
        idColumn='evidenceSeq'
        getApi='getpropertyevidence'
        createApi='createpropertyevidence'
        updateApi='updatepropertyevidence'
        deleteApi='deletepropertyevidence'
        title='Evidence'
        fieldPath='propertyEvidence'
        dialogContent={evidenceDataGridDialogContent}
        openOnClick={false}
        selectedRow={selectedRow}
        onSelectedRowChange={(newRow: any) => setSelectedRow(newRow)}
      />

      <DialogCrudDataGrid
        idColumn='chainOfCustodySeq'
        title='Evidence Chain of Custody Log'
        rows={evidenceCOC}
        columns={chainOfCustodyColumns}
        createFunction={createEvidenceCOC}
        updateFunction={updateEvidenceCOC}
        deleteFunction={deleteEvidenceCOC}
        dialogContent={evidenceCOCLogDialogContent}
        validationSchema={evidenceCOCLogSchema}
      />
    </Stack>
  );
}
