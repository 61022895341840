// // import { Add, Delete, Edit, Info } from '@mui/icons-material';
// // import {
// //   Button,
// //   Dialog,
// //   DialogActions,
// //   DialogContent,
// //   DialogTitle,
// //   Popover,
// //   Stack,
// //   Tooltip,
// // } from '@mui/material';
// // import {
// //   DataGridPremium,
// //   DataGridPremiumProps,
// //   GridActionsCellItem,
// //   GridColDef,
// //   GridRenderCellParams,
// //   GridRowParams,
// //   GridToolbarContainer,
// // } from '@mui/x-data-grid-premium';
// // import dayjs, { Dayjs, isDayjs } from 'dayjs';
// // import React, { useEffect, useState } from 'react';
// // import { v4 as uuidv4 } from 'uuid';
// // import CustomPagination from './CustomPagination';
// // import LoadingIconButton from './LoadingIconButton';
// // import { PropertyEvidenceColumn } from '../../views/Case/CaseViewModules/MortuaryModules/PropertyInventory/EvidenceDataGrid';

// // export type DialogCrudFormData = Record<string, any>;

// // export type DialogMode = 'add' | 'edit';

// // export type DialogCrudDataGridProps = Partial<DataGridPremiumProps> & {
// //   title?: string;
// //   columns: GridColDef[] | PropertyEvidenceColumn[];
// //   rows: Record<string, any>[];
// //   createFunction?: (data: DialogCrudFormData) => Promise<void>;
// //   updateFunction?: (data: DialogCrudFormData) => Promise<void>;
// //   deleteFunction?: (id: string | number) => Promise<void>;
// //   dialogContent?: (props: {
// //     mode: DialogMode;
// //     data: DialogCrudFormData;
// //     onChange: (data: DialogCrudFormData) => void;
// //   }) => React.ReactNode;
// //   idColumn?: string;
// //   legend?: React.ReactNode | null;
// //   rowsperpage?: number | null;
// //   openOnClick?: boolean;
// //   selectedRow?: Record<string, any> | null;
// //   onSelectedRowChange?: (row: Record<string, any> | null) => void;
// // };

// // export const getDateValue = (date: any): Dayjs | null => {
// //   if (date == null || date === '' || (isDayjs(date) && !date.isValid())) return null;
// //   return dayjs(date);
// // };

// // export const handleAutocompleteChange = (
// //   _: React.SyntheticEvent,
// //   value: any,
// //   fieldName: string,
// //   localData: DialogCrudFormData | null,
// //   setLocalData: React.Dispatch<React.SetStateAction<DialogCrudFormData>>,
// //   onChange: (data: DialogCrudFormData) => void
// // ): void => {
// //   const newData = { ...localData, [fieldName]: value };
// //   setLocalData(newData);
// //   onChange(newData);
// // };

// // export const handleDateChange = (
// //   value: Dayjs | null,
// //   fieldName: string,
// //   localData: DialogCrudFormData | null,
// //   setLocalData: React.Dispatch<React.SetStateAction<DialogCrudFormData>>,
// //   onChange: (data: DialogCrudFormData) => void
// // ): void => {
// //   const newData = {
// //     ...localData,
// //     [fieldName]: getDateValue(value)?.format('YYYY-MM-DDTHH:mm:ss') ?? '',
// //   };
// //   setLocalData(newData);
// //   onChange(newData);
// // };

// // export const handleTextFieldChange = (
// //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
// //   fieldName: string,
// //   localData: DialogCrudFormData | null,
// //   setLocalData: React.Dispatch<React.SetStateAction<DialogCrudFormData>>,
// //   onChange: (data: DialogCrudFormData) => void
// // ): void => {
// //   const newData = { ...localData, [fieldName]: event.target.value };
// //   setLocalData(newData);
// //   onChange(newData);
// // };

// // export const handleCheckboxChange = (
// //   event: React.ChangeEvent<HTMLInputElement>,
// //   fieldName: string,
// //   localData: DialogCrudFormData | null,
// //   setLocalData: React.Dispatch<React.SetStateAction<DialogCrudFormData>>,
// //   onChange: (data: DialogCrudFormData) => void
// // ): void => {
// //   const newData = { ...localData, [fieldName]: event.target.checked };
// //   setLocalData(newData);
// //   onChange(newData);
// // };

// // export const handleNumericTextFieldChange = (
// //   value: number,
// //   fieldName: string,
// //   localData: DialogCrudFormData | null,
// //   setLocalData: React.Dispatch<React.SetStateAction<DialogCrudFormData>>,
// //   onChange: (data: DialogCrudFormData) => void
// // ): void => {
// //   const newData = { ...localData, [fieldName]: value };
// //   setLocalData(newData);
// //   onChange(newData);
// // };

// // const DialogCrudDataGrid: React.FC<DialogCrudDataGridProps> = ({
// //   title = undefined,
// //   columns,
// //   rows,
// //   createFunction,
// //   updateFunction,
// //   deleteFunction,
// //   dialogContent,
// //   idColumn = 'id',
// //   legend = null,
// //   rowsperpage = null,
// //   openOnClick = true,
// //   selectedRow = null,
// //   onSelectedRowChange = null,
// //   ...other
// // }) => {
// //   const [open, setOpen] = useState<boolean>(false);
// //   const [dialogMode, setDialogMode] = useState<DialogMode>('add');
// //   const [currentData, setCurrentData] = useState<DialogCrudFormData>({});
// //   const [formData, setFormData] = useState<DialogCrudFormData>({});
// //   const [loading, setLoading] = useState<boolean>(false);
// //   const [rowModesModel, setRowModesModel] = useState<Record<string, any>>({});
// //   const [paginationModel, setPaginationModel] = useState({
// //     pageSize: rowsperpage ?? 5,
// //     page: 0,
// //   });
// //   const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
// //   const [deleteId, setDeleteId] = useState<string | number | null>(null);

// //   useEffect(() => {
// //     setFormData(currentData);
// //   }, [currentData]);

// //   const handleAdd = (): void => {
// //     if (createFunction && dialogContent) {
// //       setDialogMode('add');
// //       const initialData = { [idColumn]: uuidv4() };
// //       setCurrentData(initialData);
// //       setFormData(initialData);
// //       setOpen(true);
// //     }
// //   };

// //   const handleEdit = (rowData: DialogCrudFormData, event: React.MouseEvent): void => {
// //     event.stopPropagation();
// //     if (updateFunction && dialogContent) {
// //       setDialogMode('edit');
// //       setCurrentData(rowData);
// //       setFormData(rowData);
// //       setOpen(true);
// //     }
// //   };

// //   const handleDelete = (id: string | number, event: React.MouseEvent): void => {
// //     event.stopPropagation();
// //     setDeleteId(id);
// //     setConfirmDeleteOpen(true);
// //   };

// //   const handleDeleteFromDialog = (): void => {
// //     if (currentData) {
// //       setDeleteId(currentData[idColumn]);
// //       setConfirmDeleteOpen(true);
// //     }
// //   };

// //   const confirmDelete = async (): Promise<void> => {
// //     setLoading(true);
// //     setConfirmDeleteOpen(false);
// //     try {
// //       if (deleteFunction) {
// //         await deleteFunction(deleteId as string | number);
// //         if (dialogMode === 'edit' && open) {
// //           setOpen(false);
// //           setFormData({});
// //           setCurrentData({});
// //         }
// //       }
// //     } catch (error) {
// //       console.error(error);
// //     } finally {
// //       setLoading(false);
// //       setDeleteId(null);
// //     }
// //   };

// //   const cancelDelete = (): void => {
// //     setConfirmDeleteOpen(false);
// //     setDeleteId(null);
// //   };

// //   const handleSubmit = async (): Promise<void> => {
// //     setLoading(true);
// //     setOpen(false);
// //     try {
// //       if (dialogMode === 'add' && createFunction) {
// //         await createFunction(formData);
// //       } else if (dialogMode === 'edit' && updateFunction) {
// //         await updateFunction(formData);
// //       }
// //     } catch (error) {
// //       console.error(error);
// //     } finally {
// //       setLoading(false);
// //       setFormData({});
// //     }
// //   };

// //   const handleSubmitAndAdd = async (): Promise<void> => {
// //     setLoading(true);
// //     try {
// //       if (dialogMode === 'add' && createFunction) {
// //         await createFunction(formData);
// //       } else if (dialogMode === 'edit' && updateFunction) {
// //         await updateFunction(formData);
// //       }
// //     } catch (error) {
// //       console.error(error);
// //     } finally {
// //       setLoading(false);
// //       setFormData({});
// //       handleAdd();
// //     }
// //   };

// //   const handleCancel = (): void => {
// //     setOpen(false);
// //     setFormData({});
// //   };

// //   const handleFormDataChange = (data: DialogCrudFormData): void => {
// //     setFormData(prevData => ({ ...prevData, ...data }));
// //   };

// //   const handleRowClick = (params: GridRowParams): void => {
// //     if (openOnClick && updateFunction && dialogContent) {
// //       setDialogMode('edit');
// //       setCurrentData(params.row);
// //       setFormData(params.row);
// //       setOpen(true);
// //     }
// //   };

// //   const handleRowDoubleClick = (params: GridRowParams): void => {
// //     if (updateFunction && dialogContent) {
// //       setDialogMode('edit');
// //       setCurrentData(params.row);
// //       setFormData(params.row);
// //       setOpen(true);
// //     }
// //   };

// //   const actionColumn: GridColDef = {
// //     field: 'actions',
// //     headerName: 'Actions',
// //     width: 150,
// //     sortable: false,
// //     filterable: false,
// //     renderCell: (params: GridRenderCellParams): React.ReactNode => (
// //       <Stack direction='row' spacing={2}>
// //         {updateFunction && (
// //           <GridActionsCellItem
// //             icon={<Edit />}
// //             label='Edit'
// //             color='primary'
// //             className='textPrimary'
// //             onClick={event => handleEdit(params.row, event)}
// //             disabled={loading}
// //           />
// //         )}
// //         {deleteFunction && (
// //           <GridActionsCellItem
// //             icon={<Delete />}
// //             label='Delete'
// //             onClick={event => handleDelete(params.id, event)}
// //             color='error'
// //             disabled={loading}
// //           />
// //         )}
// //       </Stack>
// //     ),
// //   };

// //   const columnsWithActions =
// //     updateFunction || deleteFunction ? [...columns, actionColumn] : columns;

// //   const CustomToolbar: React.FC = () => {
// //     const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
// //     const openLegend = Boolean(anchorEl);
// //     const id = open ? 'simple-popover' : undefined;

// //     const handleOpenLegend = (event: React.MouseEvent<HTMLButtonElement>): void => {
// //       setAnchorEl(event.currentTarget);
// //     };

// //     const handleClose = (): void => {
// //       setAnchorEl(null);
// //     };

// //     return (
// //       <GridToolbarContainer sx={{ p: 1 }}>
// //         {createFunction && (
// //           <Button color='primary' startIcon={<Add />} onClick={handleAdd}>
// //             Add New {title ?? 'Entry'}
// //           </Button>
// //         )}
// //         {legend && (
// //           <React.Fragment>
// //             <Button
// //               sx={{ marginLeft: 'auto' }}
// //               color='secondary'
// //               startIcon={<Info />}
// //               onClick={handleOpenLegend}
// //             >
// //               Tox Coding
// //             </Button>

// //             <Popover
// //               id={id}
// //               open={openLegend}
// //               anchorEl={anchorEl}
// //               onClose={handleClose}
// //               anchorOrigin={{
// //                 vertical: 'bottom',
// //                 horizontal: 'center',
// //               }}
// //               transformOrigin={{
// //                 vertical: 'top',
// //                 horizontal: 'center',
// //               }}
// //             >
// //               {legend}
// //             </Popover>
// //           </React.Fragment>
// //         )}
// //       </GridToolbarContainer>
// //     );
// //   };

// //   const slots = {
// //     ...other.slots,
// //     toolbar: createFunction ? CustomToolbar : other.slots?.toolbar,
// //     pagination: CustomPagination,
// //     noRowsOverlay: () => <></>,
// //   };

// //   return (
// //     <>
// //       <DataGridPremium
// //         autoHeight // deprecated but still useful until they add in another overlay autoHeight function
// //         autosizeOptions={{
// //           includeHeaders: true,
// //           includeOutliers: true,
// //           outliersFactor: 1.5,
// //           expand: true,
// //         }}
// //         pagination
// //         paginationModel={paginationModel}
// //         onPaginationModelChange={setPaginationModel}
// //         onRowClick={handleRowClick}
// //         onRowDoubleClick={handleRowDoubleClick}
// //         columns={columnsWithActions}
// //         rows={rows}
// //         slots={slots}
// //         loading={loading || other.loading}
// //         slotProps={{
// //           // toolbar: {
// //           //   setRowModesModel,
// //           //   rowModesModel,
// //           //   rows,
// //           //   title,
// //           // },
// //           loadingOverlay: {
// //             variant: 'skeleton',
// //             noRowsVariant: 'skeleton',
// //           },
// //         }}
// //         disableColumnMenu
// //         hideFooter={rows.length <= paginationModel.pageSize}
// //         rowHeight={30}
// //         columnHeaderHeight={28}
// //         getRowId={row => row?.[idColumn]}
// //         rowSelectionModel={selectedRow ? [selectedRow[idColumn]] : []}
// //         onRowSelectionModelChange={(newSelection: any) => {
// //           if (onSelectedRowChange) {
// //             if (newSelection && newSelection.length > 0) {
// //               const selectedId = newSelection[0];
// //               const selectedRowObj = rows.find(r => r[idColumn] === selectedId) || null;
// //               onSelectedRowChange(selectedRowObj);
// //             } else {
// //               onSelectedRowChange(null);
// //             }
// //           }
// //         }}
// //         {...other}
// //       />
// //       {dialogContent && (
// //         <Dialog
// //           open={open}
// //           onClose={handleCancel}
// //           sx={{ '& .MuiPaper-root': { maxWidth: '100%', width: '80%' } }}
// //         >
// //           <DialogTitle>
// //             <Stack direction='row' justifyContent='space-between' alignItems='center'>
// //               {dialogMode === 'add' ? `Add New ${title ?? 'Entry'}` : `Edit ${title ?? 'Entry'}`}
// //               {dialogMode === 'edit' && (
// //                 <Tooltip title={`Delete ${title}`}>
// //                   <span>
// //                     <LoadingIconButton
// //                       loading={loading}
// //                       onClick={handleDeleteFromDialog}
// //                       color='error'
// //                       disabled={loading}
// //                     >
// //                       <Delete />
// //                     </LoadingIconButton>
// //                   </span>
// //                 </Tooltip>
// //               )}
// //             </Stack>
// //           </DialogTitle>
// //           <DialogContent>
// //             {dialogContent({
// //               mode: dialogMode,
// //               data: formData,
// //               onChange: handleFormDataChange,
// //             })}
// //           </DialogContent>
// //           <DialogActions>
// //             <Button onClick={handleCancel} color='error' disabled={loading}>
// //               Cancel
// //             </Button>
// //             <Button onClick={handleSubmit} color='primary' variant='contained' disabled={loading}>
// //               Submit
// //             </Button>
// //             <Button
// //               onClick={handleSubmitAndAdd}
// //               color='secondary'
// //               variant='contained'
// //               disabled={loading}
// //             >
// //               Submit and Add Another
// //             </Button>
// //           </DialogActions>
// //         </Dialog>
// //       )}
// //       <Dialog open={confirmDeleteOpen} onClose={cancelDelete}>
// //         <DialogTitle>Confirm Deletion</DialogTitle>
// //         <DialogContent>Are you sure you want to delete this {title ?? 'entry'}?</DialogContent>
// //         <DialogActions>
// //           <Button onClick={cancelDelete} color='primary' disabled={loading}>
// //             Cancel
// //           </Button>
// //           <Button onClick={confirmDelete} color='error' variant='contained' disabled={loading}>
// //             Delete
// //           </Button>
// //         </DialogActions>
// //       </Dialog>
// //     </>
// //   );
// // };

// // export default DialogCrudDataGrid;

//-----------------------------------------------------------------------------------------------------------------------------------------------

// import { Add, Delete, Edit, Info } from '@mui/icons-material';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Popover,
//   Stack,
//   Tooltip,
// } from '@mui/material';
// import {
//   DataGridPremium,
//   DataGridPremiumProps,
//   GridActionsCellItem,
//   GridColDef,
//   GridRenderCellParams,
//   GridRowParams,
//   GridToolbarContainer,
// } from '@mui/x-data-grid-premium';
// import dayjs, { Dayjs, isDayjs } from 'dayjs';
// import React, { useEffect, useState } from 'react';
// import { v4 as uuidv4 } from 'uuid';
// import CustomPagination from './CustomPagination';
// import LoadingIconButton from './LoadingIconButton';
// import { PropertyEvidenceColumn } from '../../views/Case/CaseViewModules/MortuaryModules/PropertyInventory/EvidenceDataGrid';

// export type DialogCrudFormData = Record<string, any>;

// export type DialogMode = 'add' | 'edit';

// export type DialogCrudDataGridProps = Partial<DataGridPremiumProps> & {
//   title?: string;
//   columns: GridColDef[] | PropertyEvidenceColumn[];
//   rows: Record<string, any>[];
//   createFunction?: (data: DialogCrudFormData) => Promise<void>;
//   updateFunction?: (data: DialogCrudFormData) => Promise<void>;
//   deleteFunction?: (id: string | number) => Promise<void>;
//   dialogContent?: (props: {
//     mode: DialogMode;
//     data: DialogCrudFormData;
//     onChange: (data: DialogCrudFormData) => void;
//   }) => React.ReactNode;
//   idColumn?: string;
//   legend?: React.ReactNode | null;
//   rowsperpage?: number | null;
//   /**
//    * If true (default), clicking a row will open the dialog.
//    * If false, clicking a row will select it (like a normal MUI DataGrid),
//    * and the selected row can be tracked via onSelectedRowChange.
//    */
//   openOnClick?: boolean;
//   selectedRow?: Record<string, any> | null;
//   onSelectedRowChange?: (row: Record<string, any> | null) => void;
// };

// export const getDateValue = (date: any): Dayjs | null => {
//   if (date == null || date === '' || (isDayjs(date) && !date.isValid())) return null;
//   return dayjs(date);
// };

// export const handleAutocompleteChange = (
//   _: React.SyntheticEvent,
//   value: any,
//   fieldName: string,
//   localData: DialogCrudFormData | null,
//   setLocalData: React.Dispatch<React.SetStateAction<DialogCrudFormData>>,
//   onChange: (data: DialogCrudFormData) => void
// ): void => {
//   const newData = { ...localData, [fieldName]: value };
//   setLocalData(newData);
//   onChange(newData);
// };

// export const handleDateChange = (
//   value: Dayjs | null,
//   fieldName: string,
//   localData: DialogCrudFormData | null,
//   setLocalData: React.Dispatch<React.SetStateAction<DialogCrudFormData>>,
//   onChange: (data: DialogCrudFormData) => void
// ): void => {
//   const newData = {
//     ...localData,
//     [fieldName]: getDateValue(value)?.format('YYYY-MM-DDTHH:mm:ss') ?? '',
//   };
//   setLocalData(newData);
//   onChange(newData);
// };

// export const handleTextFieldChange = (
//   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
//   fieldName: string,
//   localData: DialogCrudFormData | null,
//   setLocalData: React.Dispatch<React.SetStateAction<DialogCrudFormData>>,
//   onChange: (data: DialogCrudFormData) => void
// ): void => {
//   const newData = { ...localData, [fieldName]: event.target.value };
//   setLocalData(newData);
//   onChange(newData);
// };

// export const handleCheckboxChange = (
//   event: React.ChangeEvent<HTMLInputElement>,
//   fieldName: string,
//   localData: DialogCrudFormData | null,
//   setLocalData: React.Dispatch<React.SetStateAction<DialogCrudFormData>>,
//   onChange: (data: DialogCrudFormData) => void
// ): void => {
//   const newData = { ...localData, [fieldName]: event.target.checked };
//   setLocalData(newData);
//   onChange(newData);
// };

// export const handleNumericTextFieldChange = (
//   value: number,
//   fieldName: string,
//   localData: DialogCrudFormData | null,
//   setLocalData: React.Dispatch<React.SetStateAction<DialogCrudFormData>>,
//   onChange: (data: DialogCrudFormData) => void
// ): void => {
//   const newData = { ...localData, [fieldName]: value };
//   setLocalData(newData);
//   onChange(newData);
// };

// const DialogCrudDataGrid: React.FC<DialogCrudDataGridProps> = ({
//   title = undefined,
//   columns,
//   rows,
//   createFunction,
//   updateFunction,
//   deleteFunction,
//   dialogContent,
//   idColumn = 'id',
//   legend = null,
//   rowsperpage = null,
//   openOnClick = true,
//   selectedRow = null,
//   onSelectedRowChange = null,
//   ...other
// }) => {
//   const [open, setOpen] = useState<boolean>(false);
//   const [dialogMode, setDialogMode] = useState<DialogMode>('add');
//   const [currentData, setCurrentData] = useState<DialogCrudFormData>({});
//   const [formData, setFormData] = useState<DialogCrudFormData>({});
//   const [loading, setLoading] = useState<boolean>(false);
//   const [rowModesModel, setRowModesModel] = useState<Record<string, any>>({});
//   const [paginationModel, setPaginationModel] = useState({
//     pageSize: rowsperpage ?? 5,
//     page: 0,
//   });
//   const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
//   const [deleteId, setDeleteId] = useState<string | number | null>(null);

//   useEffect(() => {
//     setFormData(currentData);
//   }, [currentData]);

//   const handleAdd = (): void => {
//     if (createFunction && dialogContent) {
//       setDialogMode('add');
//       const initialData = { [idColumn]: uuidv4() };
//       setCurrentData(initialData);
//       setFormData(initialData);
//       setOpen(true);
//     }
//   };

//   const handleEdit = (rowData: DialogCrudFormData, event: React.MouseEvent): void => {
//     event.stopPropagation();
//     if (updateFunction && dialogContent) {
//       setDialogMode('edit');
//       setCurrentData(rowData);
//       setFormData(rowData);
//       setOpen(true);
//     }
//   };

//   const handleDelete = (id: string | number, event: React.MouseEvent): void => {
//     event.stopPropagation();
//     setDeleteId(id);
//     setConfirmDeleteOpen(true);
//   };

//   const handleDeleteFromDialog = (): void => {
//     if (currentData) {
//       setDeleteId(currentData[idColumn]);
//       setConfirmDeleteOpen(true);
//     }
//   };

//   const confirmDelete = async (): Promise<void> => {
//     setLoading(true);
//     setConfirmDeleteOpen(false);
//     try {
//       if (deleteFunction) {
//         await deleteFunction(deleteId as string | number);
//         if (dialogMode === 'edit' && open) {
//           setOpen(false);
//           setFormData({});
//           setCurrentData({});
//         }
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//       setDeleteId(null);
//     }
//   };

//   const cancelDelete = (): void => {
//     setConfirmDeleteOpen(false);
//     setDeleteId(null);
//   };

//   const handleSubmit = async (): Promise<void> => {
//     setLoading(true);
//     setOpen(false);
//     try {
//       if (dialogMode === 'add' && createFunction) {
//         await createFunction(formData);
//       } else if (dialogMode === 'edit' && updateFunction) {
//         await updateFunction(formData);
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//       setFormData({});
//     }
//   };

//   const handleSubmitAndAdd = async (): Promise<void> => {
//     setLoading(true);
//     try {
//       if (dialogMode === 'add' && createFunction) {
//         await createFunction(formData);
//       } else if (dialogMode === 'edit' && updateFunction) {
//         await updateFunction(formData);
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//       setFormData({});
//       handleAdd();
//     }
//   };

//   const handleCancel = (): void => {
//     setOpen(false);
//     setFormData({});
//   };

//   const handleFormDataChange = (data: DialogCrudFormData): void => {
//     setFormData(prevData => ({ ...prevData, ...data }));
//   };

//   const handleRowClick = (params: GridRowParams): void => {
//     // Only open the dialog if openOnClick is true.
//     if (openOnClick && updateFunction && dialogContent) {
//       setDialogMode('edit');
//       setCurrentData(params.row);
//       setFormData(params.row);
//       setOpen(true);
//     }
//   };

//   const handleRowDoubleClick = (params: GridRowParams): void => {
//     // Only open the dialog on double-click if openOnClick is true.
//     if (openOnClick && updateFunction && dialogContent) {
//       setDialogMode('edit');
//       setCurrentData(params.row);
//       setFormData(params.row);
//       setOpen(true);
//     }
//   };

//   const actionColumn: GridColDef = {
//     field: 'actions',
//     headerName: 'Actions',
//     width: 150,
//     sortable: false,
//     filterable: false,
//     renderCell: (params: GridRenderCellParams): React.ReactNode => (
//       <Stack direction='row' spacing={2}>
//         {updateFunction && (
//           <GridActionsCellItem
//             icon={<Edit />}
//             label='Edit'
//             color='primary'
//             className='textPrimary'
//             onClick={event => handleEdit(params.row, event)}
//             disabled={loading}
//           />
//         )}
//         {deleteFunction && (
//           <GridActionsCellItem
//             icon={<Delete />}
//             label='Delete'
//             onClick={event => handleDelete(params.id, event)}
//             color='error'
//             disabled={loading}
//           />
//         )}
//       </Stack>
//     ),
//   };

//   const columnsWithActions =
//     updateFunction || deleteFunction ? [...columns, actionColumn] : columns;

//   const CustomToolbar: React.FC = () => {
//     const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
//     const openLegend = Boolean(anchorEl);
//     const id = open ? 'simple-popover' : undefined;

//     const handleOpenLegend = (event: React.MouseEvent<HTMLButtonElement>): void => {
//       setAnchorEl(event.currentTarget);
//     };

//     const handleClose = (): void => {
//       setAnchorEl(null);
//     };

//     return (
//       <GridToolbarContainer sx={{ p: 1 }}>
//         {createFunction && (
//           <Button color='primary' startIcon={<Add />} onClick={handleAdd}>
//             Add New {title ?? 'Entry'}
//           </Button>
//         )}
//         {legend && (
//           <>
//             <Button
//               sx={{ marginLeft: 'auto' }}
//               color='secondary'
//               startIcon={<Info />}
//               onClick={handleOpenLegend}
//             >
//               Tox Coding
//             </Button>

//             <Popover
//               id={id}
//               open={openLegend}
//               anchorEl={anchorEl}
//               onClose={handleClose}
//               anchorOrigin={{
//                 vertical: 'bottom',
//                 horizontal: 'center',
//               }}
//               transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'center',
//               }}
//             >
//               {legend}
//             </Popover>
//           </>
//         )}
//       </GridToolbarContainer>
//     );
//   };

//   const slots = {
//     ...other.slots,
//     toolbar: createFunction ? CustomToolbar : other.slots?.toolbar,
//     pagination: CustomPagination,
//     noRowsOverlay: () => <></>,
//   };

//   return (
//     <>
//       <DataGridPremium
//         autoHeight
//         autosizeOptions={{
//           includeHeaders: true,
//           includeOutliers: true,
//           outliersFactor: 1.5,
//           expand: true,
//         }}
//         pagination
//         paginationModel={paginationModel}
//         onPaginationModelChange={setPaginationModel}
//         onRowClick={handleRowClick}
//         onRowDoubleClick={handleRowDoubleClick}
//         columns={columnsWithActions}
//         rows={rows}
//         slots={slots}
//         loading={loading || other.loading}
//         slotProps={{
//           loadingOverlay: {
//             variant: 'skeleton',
//             noRowsVariant: 'skeleton',
//           },
//         }}
//         // When openOnClick is true, disable row selection on click.
//         disableRowSelectionOnClick={openOnClick}
//         hideFooter={rows.length <= paginationModel.pageSize}
//         rowHeight={30}
//         columnHeaderHeight={28}
//         getRowId={row => row?.[idColumn]}
//         rowSelectionModel={selectedRow ? [selectedRow[idColumn]] : []}
//         onRowSelectionModelChange={(newSelection: any) => {
//           if (onSelectedRowChange) {
//             if (newSelection && newSelection.length > 0) {
//               const selectedId = newSelection[0];
//               const selectedRowObj = rows.find(r => r[idColumn] === selectedId) || null;
//               onSelectedRowChange(selectedRowObj);
//             } else {
//               onSelectedRowChange(null);
//             }
//           }
//         }}
//         {...other}
//       />
//       {dialogContent && (
//         <Dialog
//           open={open}
//           onClose={handleCancel}
//           sx={{ '& .MuiPaper-root': { maxWidth: '100%', width: '80%' } }}
//         >
//           <DialogTitle>
//             <Stack direction='row' justifyContent='space-between' alignItems='center'>
//               {dialogMode === 'add' ? `Add New ${title ?? 'Entry'}` : `Edit ${title ?? 'Entry'}`}
//               {dialogMode === 'edit' && (
//                 <Tooltip title={`Delete ${title}`}>
//                   <span>
//                     <LoadingIconButton
//                       loading={loading}
//                       onClick={handleDeleteFromDialog}
//                       color='error'
//                       disabled={loading}
//                     >
//                       <Delete />
//                     </LoadingIconButton>
//                   </span>
//                 </Tooltip>
//               )}
//             </Stack>
//           </DialogTitle>
//           <DialogContent>
//             {dialogContent({
//               mode: dialogMode,
//               data: formData,
//               onChange: handleFormDataChange,
//             })}
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleCancel} color='error' disabled={loading}>
//               Cancel
//             </Button>
//             <Button onClick={handleSubmit} color='primary' variant='contained' disabled={loading}>
//               Submit
//             </Button>
//             <Button
//               onClick={handleSubmitAndAdd}
//               color='secondary'
//               variant='contained'
//               disabled={loading}
//             >
//               Submit and Add Another
//             </Button>
//           </DialogActions>
//         </Dialog>
//       )}
//       <Dialog open={confirmDeleteOpen} onClose={cancelDelete}>
//         <DialogTitle>Confirm Deletion</DialogTitle>
//         <DialogContent>Are you sure you want to delete this {title ?? 'entry'}?</DialogContent>
//         <DialogActions>
//           <Button onClick={cancelDelete} color='primary' disabled={loading}>
//             Cancel
//           </Button>
//           <Button onClick={confirmDelete} color='error' variant='contained' disabled={loading}>
//             Delete
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default DialogCrudDataGrid;


//-----------------------------------------------------------------------------------------------------------------------------------------------

import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import dayjs, { Dayjs, isDayjs } from 'dayjs';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridToolbarContainer,
} from '@mui/x-data-grid-premium';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
  Stack,
  Tooltip,
} from '@mui/material';
import { Add, Delete, Edit, Info } from '@mui/icons-material';
import LoadingIconButton from './LoadingIconButton';
import CustomPagination from './CustomPagination';
// (Only needed if you have these in your original code)
import { PropertyEvidenceColumn } from '../../views/Case/CaseViewModules/MortuaryModules/PropertyInventory/EvidenceDataGrid';

export type DialogCrudFormData = Record<string, any>;
export type DialogMode = 'add' | 'edit';

export type DialogCrudDataGridProps = Partial<DataGridPremiumProps> & {
  title?: string;
  columns: GridColDef[] | PropertyEvidenceColumn[];
  rows: Record<string, any>[];
  createFunction?: (data: DialogCrudFormData) => Promise<void>;
  updateFunction?: (data: DialogCrudFormData) => Promise<void>;
  deleteFunction?: (id: string | number) => Promise<void>;
  dialogContent?: (props: {
    mode: DialogMode;
    data: DialogCrudFormData;
    onChange: (data: DialogCrudFormData) => void;
    validationErrors?: Record<string, string>;
  }) => React.ReactNode;
  idColumn?: string;
  legend?: React.ReactNode | null;
  rowsperpage?: number | null;
  openOnClick?: boolean;
  selectedRow?: Record<string, any> | null;
  onSelectedRowChange?: (row: Record<string, any> | null) => void;
  validationSchema?: Yup.AnySchema;
};

export const getDateValue = (date: any): Dayjs | null => {
  if (!date || date === '' || (isDayjs(date) && !date.isValid())) return null;
  return dayjs(date);
};

export const handleAutocompleteChange = (
  _: React.SyntheticEvent,
  value: any,
  fieldName: string,
  localData: DialogCrudFormData | null,
  setLocalData: React.Dispatch<React.SetStateAction<DialogCrudFormData>>,
  onChange: (data: DialogCrudFormData) => void
): void => {
  const newData = { ...localData, [fieldName]: value };
  setLocalData(newData);
  onChange(newData);
};

export const handleDateChange = (
  value: Dayjs | null,
  fieldName: string,
  localData: DialogCrudFormData | null,
  setLocalData: React.Dispatch<React.SetStateAction<DialogCrudFormData>>,
  onChange: (data: DialogCrudFormData) => void
): void => {
  const newData = {
    ...localData,
    [fieldName]: getDateValue(value)?.format('YYYY-MM-DDTHH:mm:ss') ?? '',
  };
  setLocalData(newData);
  onChange(newData);
};

export const handleTextFieldChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  fieldName: string,
  localData: DialogCrudFormData | null,
  setLocalData: React.Dispatch<React.SetStateAction<DialogCrudFormData>>,
  onChange: (data: DialogCrudFormData) => void
): void => {
  const newData = { ...localData, [fieldName]: event.target.value };
  setLocalData(newData);
  onChange(newData);
};

export const handleCheckboxChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  fieldName: string,
  localData: DialogCrudFormData | null,
  setLocalData: React.Dispatch<React.SetStateAction<DialogCrudFormData>>,
  onChange: (data: DialogCrudFormData) => void
): void => {
  const newData = { ...localData, [fieldName]: event.target.checked };
  setLocalData(newData);
  onChange(newData);
};

export const handleNumericTextFieldChange = (
  value: number,
  fieldName: string,
  localData: DialogCrudFormData | null,
  setLocalData: React.Dispatch<React.SetStateAction<DialogCrudFormData>>,
  onChange: (data: DialogCrudFormData) => void
): void => {
  const newData = { ...localData, [fieldName]: value };
  setLocalData(newData);
  onChange(newData);
};

const DialogCrudDataGrid: React.FC<DialogCrudDataGridProps> = ({
  title,
  columns,
  rows,
  createFunction,
  updateFunction,
  deleteFunction,
  dialogContent,
  idColumn = 'id',
  legend = null,
  rowsperpage = null,
  openOnClick = true,
  selectedRow = null,
  onSelectedRowChange,
  validationSchema,
  ...other
}) => {
  const [open, setOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState<DialogMode>('add');
  const [currentData, setCurrentData] = useState<DialogCrudFormData>({});
  const [formData, setFormData] = useState<DialogCrudFormData>({});
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({ pageSize: rowsperpage ?? 5, page: 0 });
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | number | null>(null);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

  useEffect(() => setFormData(currentData), [currentData]);

  useEffect(() => {
    const runValidation = async (data: DialogCrudFormData) => {
      if (!validationSchema) {
        setValidationErrors({});
        return;
      }
      try {
        await validationSchema.validate(data, { abortEarly: false });
        setValidationErrors({});
      } catch (err: any) {
        if (err.inner) {
          const errs: Record<string, string> = {};
          err.inner.forEach((e: any) => {
            if (e.path) errs[e.path] = e.message;
          });
          setValidationErrors(errs);
        } else {
          setValidationErrors({ form: 'Validation failed' });
        }
      }
    };
    void runValidation(formData);
  }, [formData, validationSchema]);

  const isFormValid = Object.keys(validationErrors).length === 0;

  const handleAdd = () => {
    if (createFunction && dialogContent) {
      setDialogMode('add');
      const initial = { [idColumn]: uuidv4() };
      setCurrentData(initial);
      setFormData(initial);
      setOpen(true);
    }
  };

  const handleEdit = (rowData: DialogCrudFormData, event: React.MouseEvent) => {
    event.stopPropagation();
    if (updateFunction && dialogContent) {
      setDialogMode('edit');
      setCurrentData(rowData);
      setFormData(rowData);
      setOpen(true);
    }
  };

  const handleDelete = (id: string | number, event: React.MouseEvent) => {
    event.stopPropagation();
    setDeleteId(id);
    setConfirmDeleteOpen(true);
  };

  const handleDeleteFromDialog = () => {
    if (currentData) {
      setDeleteId(currentData[idColumn]);
      setConfirmDeleteOpen(true);
    }
  };

  const confirmDelete = async () => {
    setLoading(true);
    setConfirmDeleteOpen(false);
    try {
      if (deleteFunction) {
        await deleteFunction(deleteId as string | number);
        if (dialogMode === 'edit' && open) {
          setOpen(false);
          setFormData({});
          setCurrentData({});
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setDeleteId(null);
    }
  };

  const cancelDelete = () => {
    setConfirmDeleteOpen(false);
    setDeleteId(null);
  };

  const handleSubmit = async () => {
    if (!isFormValid) return;
    setLoading(true);
    setOpen(false);
    try {
      if (dialogMode === 'add' && createFunction) {
        await createFunction(formData);
      } else if (dialogMode === 'edit' && updateFunction) {
        await updateFunction(formData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setFormData({});
    }
  };

  const handleSubmitAndAdd = async () => {
    if (!isFormValid) return;
    setLoading(true);
    try {
      if (dialogMode === 'add' && createFunction) {
        await createFunction(formData);
      } else if (dialogMode === 'edit' && updateFunction) {
        await updateFunction(formData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setFormData({});
      handleAdd();
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setFormData({});
  };

  const handleFormDataChange = (data: DialogCrudFormData) => {
    setFormData(prev => ({ ...prev, ...data }));
  };

  const handleRowClick = (params: GridRowParams) => {
    if (openOnClick && updateFunction && dialogContent) {
      setDialogMode('edit');
      setCurrentData(params.row);
      setFormData(params.row);
      setOpen(true);
    }
  };

  const actionColumn: GridColDef = {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Stack direction='row' spacing={2}>
        {updateFunction && (
          <GridActionsCellItem
            icon={<Edit />}
            label='Edit'
            color='primary'
            onClick={event => handleEdit(params.row, event)}
            disabled={loading}
          />
        )}
        {deleteFunction && (
          <GridActionsCellItem
            icon={<Delete />}
            label='Delete'
            onClick={event => handleDelete(params.id, event)}
            color='error'
            disabled={loading}
          />
        )}
      </Stack>
    ),
  };

  const columnsWithActions = (updateFunction || deleteFunction)
    ? [...columns, actionColumn]
    : columns;

  const CustomToolbar: React.FC = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const openLegend = Boolean(anchorEl);

    return (
      <GridToolbarContainer sx={{ p: 1 }}>
        {createFunction && (
          <Button color='primary' startIcon={<Add />} onClick={handleAdd}>
            Add New {title ?? 'Entry'}
          </Button>
        )}
        {legend && (
          <>
            <Button
              sx={{ marginLeft: 'auto' }}
              color='secondary'
              startIcon={<Info />}
              onClick={e => setAnchorEl(e.currentTarget)}
            >
              Tox Coding
            </Button>
            <Popover
              open={openLegend}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              {legend}
            </Popover>
          </>
        )}
      </GridToolbarContainer>
    );
  };

  const slots = {
    ...other.slots,
    toolbar: createFunction ? CustomToolbar : other.slots?.toolbar,
    pagination: CustomPagination,
    noRowsOverlay: () => <></>,
  };

  return (
    <>
      <DataGridPremium
        autoHeight
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onRowClick={handleRowClick}
        columns={columnsWithActions}
        rows={rows}
        slots={slots}
        loading={loading || other.loading}
        slotProps={{ loadingOverlay: { variant: 'skeleton', noRowsVariant: 'skeleton' } }}
        disableRowSelectionOnClick={openOnClick}
        hideFooter={rows.length <= paginationModel.pageSize}
        rowHeight={30}
        columnHeaderHeight={28}
        getRowId={row => row?.[idColumn]}
        rowSelectionModel={selectedRow ? [selectedRow[idColumn]] : []}
        onRowSelectionModelChange={newSel => {
          if (onSelectedRowChange) {
            if (newSel?.length > 0) {
              const selId = newSel[0];
              const selRow = rows.find(r => r[idColumn] === selId) || null;
              onSelectedRowChange(selRow);
            } else onSelectedRowChange(null);
          }
        }}
        {...other}
      />

      {dialogContent && (
        <Dialog open={open} onClose={handleCancel} sx={{ '& .MuiPaper-root': { width: '80%' } }}>
          <DialogTitle>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              {dialogMode === 'add' ? `Add New ${title ?? 'Entry'}` : `Edit ${title ?? 'Entry'}`}
              {dialogMode === 'edit' && (
                <Tooltip title={`Delete ${title}`}>
                  <span>
                    <LoadingIconButton
                      loading={loading}
                      onClick={handleDeleteFromDialog}
                      color='error'
                      disabled={loading}
                    >
                      <Delete />
                    </LoadingIconButton>
                  </span>
                </Tooltip>
              )}
            </Stack>
          </DialogTitle>
          <DialogContent>
            {dialogContent({
              mode: dialogMode,
              data: formData,
              onChange: handleFormDataChange,
              validationErrors,
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color='error' disabled={loading}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} color='primary' variant='contained' disabled={loading || !isFormValid}>
              Submit
            </Button>
            <Button
              onClick={handleSubmitAndAdd}
              color='secondary'
              variant='contained'
              disabled={loading || !isFormValid}
            >
              Submit and Add Another
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Dialog open={confirmDeleteOpen} onClose={cancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this {title ?? 'entry'}?</DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color='primary' disabled={loading}>
            Cancel
          </Button>
          <Button onClick={confirmDelete} color='error' variant='contained' disabled={loading}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogCrudDataGrid;
