import { GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { dateColumnType } from '../../../../../utils/components/CrudDataGrid/DateColumn';
import ReportDataGrid from '../../../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function ELabPMToxReportingTestView() {
  const [loading, setLoading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const formik = useFormikContext();

  useEffect(() => {
    console.log('labresultstestlist', formik.values?.toxreportingtestlist);
  }, [formik.values]);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'samplenumber',
      headerName: 'Sample Number',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      cellClassName: params => {
        // Use the row's paramname field (which contains the status info)
        const paramValue = params.row.paramname;
        if (typeof paramValue !== 'string') return '';
        // Look for STATUSGREEN, STATUSRED, or STATUSYELLOW in the paramname field
        const statusMatch = paramValue.match(/\bSTATUS(RED|GREEN|YELLOW)\b/i);
        const status = statusMatch && statusMatch[1] ? statusMatch[1].toLowerCase() : 'silver';
        return `param-status-${status}`;
      },
    },

    {
      field: 'testname',
      headerName: 'Test Name',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'testrun',
      headerName: 'Test Run',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'paramname',
      headerName: 'Parameter Name',
      flex: 1,
      minwidth: 150,
      editable: false,
      align: 'center',
      headeralign: 'center',
      headerclassname: 'header-theme',
      // use cellclassname to add a class based on a status marker embedded in the value.
      cellClassName: params => {
        if (typeof params.value !== 'string') return '';
        console.log('Cell Value:', params.value);
        // Look for STATUSGREEN or STATUSRED or STATUSYELLOW (case-insensitive).
        // This captures the color part (green, red, or yellow) in group 1.
        const statusMatch = params.value.match(/\bSTATUS(RED|GREEN|YELLOW)\b/i);

        console.log('Status Match:', statusMatch);

        // If we got a match, convert that group to lowercase, otherwise default to "silver".
        const status = statusMatch && statusMatch[1] ? statusMatch[1].toLowerCase() : 'silver';
        return `param-status-${status}`;
      },

      // render just the inner text (if available) so that each cell shows the correct value.
      renderCell: params => {
        if (typeof params.value !== 'string') return params.value;
        const textMatch = params.value.match(/>(.*?)<\/td>/);
        const text = textMatch ? textMatch[1] : params.value;
        return <>{text}</>;
      },
    },
    {
      field: 'concresult',
      headerName: 'Concentration Results',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'unit',
      headerName: 'Unit Name',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'accessmentname',
      headerName: 'Acessment Name',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      ...dateColumnType,
      valueGetter: value => {
        if (!value) return undefined;
        if (value === 'Invalid Date') {
          return 'undefined';
        }
        return new Date(value);
      },
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'reportable',
      headerName: 'Reportable',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'range',
      headerName: 'Range',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  useEffect(() => {
    if (formik.values != null) {
      setlistresults(formik.values?.toxreportingtestlist);
    }
    console.log('labresults', formik.values?.toxreportingtestlist);
  }, [formik.values]);

  return (
    <ReportDataGrid
      loading={loading}
      idcolumn='id'
      listresults={listresults ?? []}
      columnsInput={columns}
      customButtons={[<GridToolbarQuickFilter key='filter' />]}
      title=''
      sx={{
        '& .param-status-green': {
          backgroundColor: '#1e4620', // dark green
          color: '#b9f6ca', // light mint text
          fontWeight: 'bold',
        },
        '& .param-status-yellow': {
          backgroundColor: '#4a3e10', // deep mustard brown
          color: '#fff59d', // soft yellow text
          fontWeight: 'bold',
        },
        '& .param-status-red': {
          backgroundColor: '#5c1a1a', // dark maroon
          color: '#ff8a80', // soft red-pink text
          fontWeight: 'bold',
        },
        '& .param-status-silver': {},
      }}
    />
  );
}
