import { DownloadRounded, OpenInNewRounded } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Portal,
  Snackbar,
  SxProps,
  Theme,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useEffect, useRef, useState, useMemo } from 'react';
import { useAuth } from '../auth/AuthService';
import { FileAPI } from '../functions/FileAPI';
import { Download } from '@mui/icons-material';

const saveFile = async (blob: Blob, suggestedName: string) => {
  const supportsFileSystemAccess =
    'showSaveFilePicker' in window &&
    (() => {
      try {
        return window.self === window.top;
      } catch {
        return false;
      }
    })();

  if (supportsFileSystemAccess) {
    try {
      const handle = await (window as any).showSaveFilePicker({
        suggestedName,
      });
      const writable = await handle.createWritable();
      await writable.write(blob);
      await writable.close();
      return;
    } catch (err) {
      console.log(err);
    }
  }

  const blobURL = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = blobURL;
  a.download = suggestedName;
  a.style.display = 'none';
  document.body.append(a);
  a.click();
  setTimeout(() => {
    URL.revokeObjectURL(blobURL);
    a.remove();
  }, 1000);
};

type OpenFileParams = {
  file?: File;
  blob?: Blob;
  filename: string;
  onClose: () => void;
};

const OpenFileInModal = ({ file, blob, filename, onClose }: OpenFileParams) => {
  const [dialogOpen, setDialogOpen] = useState(true);
  let fileBlob: Blob = file || blob!;
  const blobUrl = useMemo(() => URL.createObjectURL(fileBlob), [fileBlob]);

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth='lg'
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            height: '90vh',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <Box
          component={'div'}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          padding='16px'
        >
          <DialogTitle style={{ margin: 0, padding: 0 }}>MRO Preview</DialogTitle>
          <Box component={'div'}>
            <Button
              startIcon={<Download />}
              onClick={() => saveAs(fileBlob, filename)}
              color='primary'
              style={{ marginRight: 8 }}
            >
              Download PDF
            </Button>

            <Button
              onClick={() => {
                setDialogOpen(false);
                onClose();
              }}
              color='error'
            >
              Close
            </Button>
          </Box>
        </Box>
        <DialogContent
          dividers
          sx={{
            flex: 1,
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <iframe
            title='PDF Preview'
            src={`${blobUrl}#zoom=page-fit&view=FitH&toolbar=0`}
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              flexGrow: 1,
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

type OpenFileButtonParams = {
  fileSeq: string;
  caseid: string;
  filename: string;
  buttonSx?: SxProps<Theme>;
  buttonVariant?: 'text' | 'outlined' | 'contained';
  buttonColor?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  buttonSize?: 'small' | 'medium' | 'large';
  onOpenSuccess?: () => void;
  onOpenError?: (error: Error) => void;
  endpoint?: string;
};

type OpenFileError = {
  display: boolean;
  title: string;
  message: string;
};

export function OpenFileButton({
  fileSeq,
  caseid,
  filename,
  buttonVariant = undefined,
  buttonColor = undefined,
  buttonSize = undefined,
  buttonSx = undefined,
  onOpenSuccess,
  onOpenError,
  endpoint,
}: OpenFileButtonParams) {
  const auth = useAuth();
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState<OpenFileError>({ display: false, title: '', message: '' });
  const [progressSize, setProgressSize] = useState(16);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [fileData, setFileData] = useState<{ file?: File; blob?: Blob; filename?: string } | null>(null);

  useEffect(() => {
    if (buttonRef.current) {
      const { fontSize } = window.getComputedStyle(buttonRef.current);
      setProgressSize(parseFloat(fontSize));
    }
  }, [filename]);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  async function handleFileAction(action: 'open' | 'download') {
    try {
      setDownloading(true);
      const { blob, filename } = await FileAPI.downloadFileBlob({
        accessToken: auth.user?.accessToken!,
        caseid,
        fileSeq,
        endpoint,
      });

      if (blob instanceof Blob) {
        const file = new File([blob], filename, { type: blob.type });
        if (file.type !== 'application/pdf') {
          saveFile(blob, filename);
          return;
        }
        if (action === 'open') {
          setFileData({ file, filename });
        } else {
          saveFile(blob, filename);
        }
      }

      onOpenSuccess?.();
    } catch (error) {
      setError({
        display: true,
        title: 'File not found',
        message: `We could not find a file named ${filename}`,
      });
      onOpenError?.(error as Error);
    } finally {
      setDownloading(false);
      handleClose();
    }
  }

  return (
    <>
      <Portal>
        <Snackbar
          open={error.display}
          autoHideDuration={5000}
          onClose={() => setError({ display: false, title: '', message: '' })}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <Alert severity='error'>
            <AlertTitle>{error.title}</AlertTitle>
            {error.message}
          </Alert>
        </Snackbar>
      </Portal>

      <Button
        disabled={downloading}
        startIcon={downloading && <CircularProgress size={progressSize} />}
        onClick={() => handleFileAction('open')}
        onContextMenu={handleContextMenu}
        variant={buttonVariant}
        color={buttonColor}
        size={buttonSize}
        sx={buttonSx}
        ref={buttonRef}
      >
        <Typography variant='body2'>{filename?.trim()}</Typography>
      </Button>

      {fileData && <OpenFileInModal {...fileData} onClose={() => setFileData(null)} />}
    </>
  );
}
