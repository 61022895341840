import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useAuth } from '../../../../../utils/auth/AuthService';
import DialogCrudDataGrid, { DialogCrudFormData, DialogMode } from '../../../../../utils/components/DialogCrudDataGrid';
import { GridColDef } from '@mui/x-data-grid-premium';
import { PropertyEvidenceColumn } from './EvidenceDataGrid';

interface PropertyInventoryDataGridProps {
  rows: Record<string, any>[];
  columns: GridColDef[] | PropertyEvidenceColumn[];
  dialogContent?: (props: {
    mode: DialogMode;
    data: DialogCrudFormData;
    onChange: (data: DialogCrudFormData) => void;
  }) => React.ReactNode;
  fieldPath: string;
  idColumn: string;
  title: string;
  getApi: string;
  createApi: string;
  updateApi: string;
  deleteApi: string;
  [key: string]: any; // For other props
}

export function PropertyInventoryDataGrid({
  rows,
  columns,
  dialogContent,
  fieldPath,
  idColumn,
  title,
  getApi,
  createApi,
  updateApi,
  deleteApi,
  ...other
}: PropertyInventoryDataGridProps) {
  const { user } = useAuth();
  const { VITE_API_URL, VITE_FASTAPI_URL } = import.meta.env;
  const formik = useFormikContext<CMSCase>();

  const apiUrl = idColumn.includes('evidence') ? VITE_FASTAPI_URL : VITE_API_URL

  useEffect(() => {
    fetchPropertyInventory();
  }, []);

  const fetchPropertyInventory = async () => {
    await fetch(apiUrl + `${getApi}?caseSeq=${formik.values?.caseSummary?.caseSeq}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    })
      .then(res => {
        if (res.status === 204) {
          return;
        }
        return res.json();
      })
      .then(data => {
        formik.setFieldValue(`caseMortuary.propertyInventory.${fieldPath}`, data);
      })
      .catch(e => {
        console.error(e);
      });
  };

  interface NewRow {
    [key: string]: any; // Replace with a more specific type if available
  }

  const createPropertyInventory = async (newRow: NewRow): Promise<void> => {
    let formData = new FormData();
    formData.append('newRow', JSON.stringify(newRow));
    formData.append('caseSeq', formik.values?.caseSummary?.caseSeq);

    await fetch(apiUrl + createApi, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.error(e);
      });

    await fetchPropertyInventory();
  };

  interface UpdatedRow {
    [key: string]: any; // Replace with a more specific type if available
  }

  const updatePropertyInventory = async (updatedRow: UpdatedRow): Promise<void> => {
    let formData = new FormData();
    formData.append('updatedRow', JSON.stringify(updatedRow));

    await fetch(apiUrl + updateApi, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
      body: formData,
    })
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch(e => {
        console.error(e);
      });

    await fetchPropertyInventory();
  };

  const deletePropertyInventory = async (id: string | number): Promise<void> => {
    await fetch(apiUrl + `${deleteApi}/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    })
      .then((res: Response) => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.text();
      })
      .catch((e: Error) => {
        console.error(e);
      });

    await fetchPropertyInventory();
  };

  return (
    <DialogCrudDataGrid
      rows={rows}
      columns={columns}
      idColumn={idColumn}
      createFunction={createPropertyInventory}
      updateFunction={updatePropertyInventory}
      deleteFunction={deletePropertyInventory}
      title={title}
      dialogContent={dialogContent}
      {...other}
    />
  );
}
