import React, { useEffect, useState } from 'react';
import { FiActivity } from 'react-icons/fi';
import { GiBookshelf, GiMicroscope, GiSecretBook, GiPerson } from 'react-icons/gi';

import { Box, Button, Grid, Divider } from '@mui/material';
import { MdAccessTime, MdOutlineHealthAndSafety } from 'react-icons/md';
import { useNavigate } from 'react-router';
import '../../App.css';
import { useAuth } from '../../utils/auth/AuthService';
import Breadcrumb from '../../components/AdministrationModules/Breadcrumb';

export default function AdminReports() {
  const { user } = useAuth();
  const [isaccessViolentDeathReport, setaccessViolentDeathReport] = useState(false);

  const navigate = useNavigate();

  const OpenAvgTurnaroundTime = () => {
    navigate('/reports/turnaroundtimereport', { state: { name: 'messageFromServer' } });
  };

  const OpenMETurnaroundTime = () => {
    navigate('/reports/meturnaroundtimereport', {
      state: { name: 'messageFromServer' },
    });
  };

  const OpenViolent = () => {
    navigate('/reports/violentdeath', { state: { name: 'messageFromServer' } });
  };

  const OpenOpioid = () => {
    navigate('/reports/opioiddeath', { state: { name: 'messageFromServer' } });
  };

  const OpenCaseResponseTime = () => {
    navigate('/reports/caseresponsetime', { state: { name: 'messageFromServer' } });
  };

  const OpenCaseSearch = () => {
    navigate('/reports/casesearch', { state: { name: 'messageFromServer' } });
  };

  const OpenDrugToxicity = () => {
    navigate('/reports/drugtoxicity', { state: { name: 'messageFromServer' } });
  };

  const OpenSuicide = () => {
    navigate('/reports/suicide', { state: { name: 'messageFromServer' } });
  };

  const OpenNewSuicide = () => {
    navigate('/reports/newsuicide', { state: { name: 'messageFromServer' } });
  };

  const OpenMyCases = () => {
    navigate('/reports/mycases', { state: { name: 'messageFromServer' } });
  };

  const OpenInvestigatorByScene = () => {
    navigate('/reports/sceneexaminedby', { state: { name: 'messageFromServer' } });
  };

  const OpenCasesCreatedByGeneralStaff = () => {
    navigate('/reports/casesbygeneralstaff', { state: { name: 'messageFromServer' } });
  };
  const OpenAdminAssistantCases = () => {
    navigate('/reports/opencasesbyadmin', { state: { name: 'messageFromServer' } });
  };
  const OpenExamsReportSummary = () => {
    navigate('/reports/examssummaryreport', { state: { name: 'messageFromServer' } });
  };

  const OpenPathologistActivityReport = () => {
    navigate('/reports/pathologistactivityreport', {
      state: { name: 'messageFromServer' },
    });
  };

  const OpenPathologistCasesView = () => {
    navigate('/reports/openpathologistcases', { state: { name: 'messageFromServer' } });
  };

  const OpenInvestigatorCaseActivityReport = () => {
    navigate('/reports/investigatoractivityreport', {
      state: { name: 'messageFromServer' },
    });
  };

  const OpenMissingCaseDocumentsView = () => {
    navigate('/reports/missingcasedocuments', { state: { name: 'messageFromServer' } });
  };

  const OpenJurisdictionActivityReport = () => {
    navigate('/reports/jurisdictionactivityreport', { state: { name: 'messageFromServer' } });
  };

  const OpenCasesPastDueReport = () => {
    navigate('/reports/casespastduereport', { state: { name: 'messageFromServer' } });
  };

  const OpenMissingDemographicsReport = () => {
    navigate('/reports/missingdemographicsreport', {
      state: { name: 'messageFromServer' },
    });
  };

  const OpenMissingReasonforInvestigationReport = () => {
    navigate('/reports/missingreasonforinvestigationreport', {
      state: { name: 'messageFromServer' },
    });
  };

  const OpenMTCaseActivityExtraction = () => {
    navigate('/reports/morguetechcaseactivity', {
      state: { name: 'messageFromServer' },
    });
  };

  const OpenDataEntryResponseTimeReport = () => {
    navigate('/reports/dataentryresponsetimereport', {
      state: { name: 'messageFromServer' },
    });
  };

  const OpenDrugToxAudit = () => {
    navigate('/reports/drugtoxaudit', { state: { name: 'messageFromServer' } });
  };

  useEffect(() => {
    if (user === null) return;
    //Checking permission for Violent death report. Report list contained comma (,) seperated value of report list seqs
    // 5045F461-B62C-4240-B193-24B184A44A87  Violent Death report seq
    const violentDeathReportSeq = '5045F461-B62C-4240-B193-24B184A44A87';
    const reportList = user.reportAccessList.toString().toLowerCase();

    var hasAccessViolentDeathReport = reportList.indexOf(violentDeathReportSeq.toLowerCase()) != -1;
    if (hasAccessViolentDeathReport) setaccessViolentDeathReport(true);
    else setaccessViolentDeathReport(true); //set to true always while testing
  }, [user]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenMissingCaseDocumentsView();
            }}
            startIcon={<GiBookshelf />}
          >
            MISSING DOCUMENTS
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenPathologistActivityReport();
            }}
            startIcon={<FiActivity />}
            // disabled
          >
            PATHOLOGIST ACTIVITY
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenPathologistCasesView();
            }}
            startIcon={<GiMicroscope />}
            // disabled
          >
            PATHOLOGIST OPEN EXAMS
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenInvestigatorCaseActivityReport();
            }}
            startIcon={<GiSecretBook />}
            // disabled
          >
            INVESTIGATOR ACTIVITY
          </Button>
        </Grid>
      </Grid>
      <hr />

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px', maxHeight: '72px' }}
            onClick={event => {
              OpenMTCaseActivityExtraction();
            }}
            startIcon={<GiSecretBook />}
            // disabled
          >
            Morgue Tech Activity
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenInvestigatorByScene();
            }}
            startIcon={<MdOutlineHealthAndSafety />}
            // disabled
          >
            SCENE EXAMINED BY
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          {/*This report is not actually implemented..*/}
          {/*<Button variant="contained" color="info" style={{ width: '100%' }} onClick={(event) => { OpenDataEntryResponseTimeReport(); }} startIcon={<MdAccessTime />}>*/}
          {/*    DATA ENTRY RESPONSE TIME*/}
          {/*</Button>*/}

          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px', maxHeight: '72px' }}
            onClick={event => {
              OpenDrugToxAudit();
            }}
            startIcon={<GiSecretBook />}
            disabled
          >
            DrugTox Audit
          </Button>
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenMETurnaroundTime();
            }}
            startIcon={<MdAccessTime />}
            // disabled
          >
            ME TURN AROUND TIME
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenCasesCreatedByGeneralStaff();
            }}
            startIcon={<MdOutlineHealthAndSafety />}
            // disabled
          >
            Case History by General Staff
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            variant='contained'
            style={{ width: '100%', minHeight: '72px' }}
            onClick={event => {
              OpenAdminAssistantCases();
            }}
            startIcon={<GiSecretBook />}
            // disabled
          >
            Admin Assistant Open Cases
          </Button>
        </Grid>       
      </Grid>
    </Box>
  );
}
