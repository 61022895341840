import { Button, SxProps, Theme } from '@mui/material';
import { DataGridPremiumProps, GridColDef } from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../../App.css';
import CondensedReportDataGrid from './CondensedReportDataGrid';
import ExpandedReportDataGrid from './ExpandedReportDataGrid';

interface ReportDataGridProps extends Omit<DataGridPremiumProps, 'rows' | 'columns'> {
  loading?: boolean;
  listresults?: any[];
  columnsInput: GridColDef[];
  title?: string | null;
  headerAlignment?: 'left' | 'center' | 'right';
  columnAlignment?: 'left' | 'center' | 'right';
  chatcontent?: any | null;
  idcolumn: string;
  casecount?: number | null;
  rowsperpage?: number;
  customButtons?: React.ReactNode[];
  sx?: SxProps<Theme>;
}

export default function ReportDataGrid({
  loading = false,
  listresults,
  columnsInput,
  title = null,
  headerAlignment = 'left',
  columnAlignment = 'left',
  chatcontent = null,
  idcolumn,
  casecount = null,
  rowsperpage = 5,
  customButtons = [],
  sx = {},
  ...props
}: ReportDataGridProps) {
  const [updatedColumns, setUpdatedColumns] = useState<GridColDef[]>([]);
  const [expanded, setExpanded] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleCaseClick = (event: React.MouseEvent<HTMLButtonElement>, data: any) => {
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };

  useEffect(() => {
    var count = 1;
    if (listresults) {
      for (var row of listresults) {
        row.rownumber = count;
        count += 1;
      }
    }

    for (var column of columnsInput) {
      column.align = columnAlignment;
      column.headerAlign = headerAlignment;
      column.headerClassName = 'header-theme';
      column.flex = column?.flex || 1;

      if (column.width != null) {
        column.minWidth = column.width;
      }

      if (column.field == 'caseid' || column.field == 'caseID') {
        column.renderCell = params => {
          return (
            <Button
              variant='text'
              color='primary'
              sx={{ fontFamily: 'DataGrid, sans-serif', fontWeight: 700 }}
              onClick={event => {
                handleCaseClick(event, params);
              }}
            >
              {params.formattedValue}
            </Button>
          );
        };
      }

      if (column.field == 'id' || column.field == 'ID') {
        column.hideable = false;
      }
    }

    setUpdatedColumns(columnsInput);
  }, [listresults]);

  return expanded ? (
    <ExpandedReportDataGrid
      {...props}
      loading={loading}
      rows={listresults ?? []}
      columns={updatedColumns}
      idcolumn={idcolumn}
      expanded={expanded}
      setExpanded={setExpanded}
      customButtons={customButtons}
      //@ts-ignore
      sx={sx}
    />
  ) : (
    <CondensedReportDataGrid
      {...props}
      loading={loading}
      columns={updatedColumns}
      rows={listresults ?? []}
      idcolumn={idcolumn}
      title={title}
      chatcontent={chatcontent}
      // @ts-ignore
      casecount={casecount}
      rowsperpage={rowsperpage}
      expanded={expanded}
      setExpanded={setExpanded}
      // @ts-ignore
      customButtons={customButtons}
      //@ts-ignore
      sx={sx}
    />
  );
}
