import { AssignmentInd, LocalShippingRounded, People } from '@mui/icons-material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';
import CustomHeader from '../../utils/components/CustomHeader';
import { AllRoles } from '../../utils/constants/AllRoles';
import { LiveryCheckinView } from '../Livery/LiveryCheckInView';
import { YourAccountRequestView } from './YourProfileModules/YourAccountRequestsView';
import YourCasesView from './YourProfileModules/YourCasesView';
import YourReportsView from './YourProfileModules/YourReportsView';
import TreeDrawer from '../../utils/components/TreeDrawer';
import TreeTabPanel from '../../utils/components/TreeTabPanel';
import MasterUserList from './YourProfileModules/OpenMasterUserList';

export default function YourCasesAndReportsView() {
  const auth = useAuth();
  const hasAccessToLivery = auth.user?.roleCheck([AllRoles.Livery]) ?? false;
  const hasAccessToAccountRequests = auth.user?.roleCheck(['bbe']) ?? false;

  const [currentTab, setCurrentTab] = useState('0');
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  // Dynamically build drawer contents based on permissions
  const drawerContents = [
    { id: 'your_cases', label: 'Your Cases', icon: <People /> },
    { id: 'your_reports', label: 'Your Reports', icon: <AssessmentIcon /> },
    ...(hasAccessToAccountRequests
      ? [
          {
            id: 'user_accounts',
            label: 'User Accounts',
            children: [
              { id: 'account_requests', label: 'Account Requests', icon: <AssignmentInd /> },
              { id: 'user_list', label: 'User List', icon: <People /> },
            ],
          },
        ]
      : []),
  ];

  // Only add livery tab if user has permission
  if (hasAccessToLivery) {
    drawerContents.push({ id: 'your_transport', label: 'Your Transport', icon: <LocalShippingRounded /> });
  }

  return (
    <Box
      component='section'
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <TreeDrawer
        drawerContents={drawerContents}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />

      {/* Page Contents */}
      <Box component='section' sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
        {/* <hr /> */}
        {/* Cases Tab */}
        <TreeTabPanel value={currentTab} index={'your_cases'}>
          <CustomHeader
            title='Your Case History'
            description='View all your cases, details and statistics'
            handleMenuClick={handleMenuClick}
          />
          <YourCasesView />
        </TreeTabPanel>

        {/* Reports Tab */}
        <TreeTabPanel value={currentTab} index={'your_reports'}>
          <CustomHeader
            title='Reports'
            description='Create quick assessments based on your User data'
            handleMenuClick={handleMenuClick}
          />
          <YourReportsView />
        </TreeTabPanel>

        {/* Account Requests Tab - Only rendered if user has access */}
          <TreeTabPanel value={currentTab} index={'account_requests'}>
            <CustomHeader
              title='Your Account Requests'
              description={`Manage account requests you've submitted`}
              handleMenuClick={handleMenuClick}
            />
            <YourAccountRequestView />
          </TreeTabPanel>

          <TreeTabPanel value={currentTab} index={'user_list'}>
            <CustomHeader
              title='User List'
              description={`This list displays the users in the jurisdictions you have access to.`}
              handleMenuClick={handleMenuClick}
            />
            <MasterUserList />
          </TreeTabPanel>

        {/* Livery Tab - Only rendered if user has access */}
        {hasAccessToLivery && (
          // @ts-ignore
          <TreeTabPanel value={currentTab} index={'your_transport'}>
            <CustomHeader
              title='Your Transport'
              description='Manage transport assignments'
              handleMenuClick={handleMenuClick}
            />
            <LiveryCheckinView />
          </TreeTabPanel>
        )}
      </Box>
    </Box>
  );
}
