import InfoIcon from '@mui/icons-material/Info';
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  TextField,
  Tooltip,
  useTheme,
  Box,
  Chip,
  FormLabel,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import '../../../App.css';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';
import { formatPhoneNumber } from '../../../utils/functions/formatPhoneNumber';
import { sortOptionsAlphabetically } from '../../../utils/functions/sortOptionsAlphabetically';
import { getAllJurisdiction } from '../../../services/utility-api';

export default function OpenMasterUserList() {
  const { user } = useAuth();
  const [loading, setloading] = useState(false);
  const [listresults, setListResults] = useState([]);
  const [jurisdictionOptions, setJurisdictionOptions] = useState<[] | any[]>([]);
  const [selectedJdx, setSelectedJdx] = useState<null>(null);
  const { VITE_API_URL } = import.meta.env;
  const theme = useTheme();
  const [showActive, setShowActive] = useState<string>('active');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [filteredList, setFilteredList] = useState<any[]>([]);
  const hasSystemAdminRole = user?.roleCheck(['451']);

  const columns = [
    {
      field: 'userName',
      headerName: 'User ID',
      width: 150,
    },
    { field: 'personLastName', headerName: 'Last Name', width: 150, flex: 1 },
    { field: 'personFirstName', headerName: 'First Name', width: 150, flex: 1 },
    { field: 'primaryEmail', headerName: 'Email', width: 150, flex: 1 },
    {
      field: 'primaryMobileNo',
      headerName: 'Mobile No',
      flex: 1,
      width: 150,
      valueGetter: (value: string | null) => formatPhoneNumber(value ?? '', true),
    },
    {
      field: 'jurisdictions',
      headerName: 'Jurisdictions',
      width: 200,
      flex: 2,
      renderCell: (params: any) => {
        const jurisdictions = (params.value || []).map(
          (jurisdiction: { jdxName: string }) => jurisdiction.jdxName
        );
        return (
          <Box
            component={'div'}
            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
          >
            {jurisdictions.map((jdxName: string, index: number) => (
              <Chip
                key={index}
                label={jdxName}
                size='small'
                sx={{
                  // height: 'auto',
                  '& .MuiChip-label': {
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'normal',
                  },
                }}
              />
            ))}
          </Box>
        );
      },
    },
    {
      field: 'roles',
      headerName: 'Roles',
      width: 200,
      flex: 2,
      renderCell: (params: any) => {
        const roles = (params.value || []).map((role: { roleName: string }) => role.roleName);
        return (
          <Box
            component={'div'}
            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
          >
            {roles.map((roleName: string, index: number) => (
              <Chip
                key={index}
                label={roleName}
                size='small'
                sx={{
                  // height: 'auto',
                  '& .MuiChip-label': {
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'normal',
                  },
                }}
              />
            ))}
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      Width: 100,
      align: 'left',
      flex: 1,
    },
  ];

  const handleSubmit = async () => {
    if (user === null) return;

    setloading(true);

    if (user && user.token) {
      await fetch(VITE_API_URL + 'getmasterusers', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setListResults(data);
          setloading(false);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }

    setloading(false);
  };

  const fetchJurisdictionOptions = async () => {
      try {
        if (hasSystemAdminRole) {
          const response = await getAllJurisdiction();
          const transformedResponse = response?.map((jurisdiction: any) => ({
            jdxSeq: jurisdiction.optionSeq,
            jdxName: jurisdiction.optionName,
          }));
          setJurisdictionOptions(sortOptionsAlphabetically(transformedResponse ?? [], 'jdxName'));
        } else {
          setJurisdictionOptions(sortOptionsAlphabetically(user?.jdxAccessList ?? [], 'jdxName'));
        }
      } catch (error) {
        console.error('Error fetching jurisdiction options:', error);
        setJurisdictionOptions([]);
      }
    };


  useEffect(() => {
    if (user === null) return;
    fetchJurisdictionOptions();
    handleSubmit();
  }, []);

  useEffect(() => {
    let filtered = [...listresults];

    // Filter by jurisdiction if selected
    if (selectedJdx) {
        filtered = filtered?.filter((row : any) => 
          row?.jurisdictions?.some((jdx: any) => 
            jdx.jdxSeq?.toLowerCase() === selectedJdx?.jdxSeq?.toLowerCase()
          )
        );
      }

    // Apply search and active status filters
    filtered = filtered.filter((row: any) => {
      const searchTerm = searchFilter.toLowerCase();
      const matchesSearch =
        searchFilter.trim() === '' ||
        row?.personLastName?.toLowerCase().includes(searchTerm) ||
        row?.personFirstName?.toLowerCase().includes(searchTerm) ||
        row?.roles?.some((role: any) => role.roleName.toLowerCase().includes(searchTerm));

      if (showActive.toLowerCase() === 'all') return matchesSearch;

      const isActiveMatch =
        showActive === 'active' ? row?.isActive === true : row?.isActive === false;

      return isActiveMatch && matchesSearch;
    });

    setFilteredList(filtered);
  }, [listresults, showActive, searchFilter, selectedJdx]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
              <b>Filters:</b>
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby='active-status'
                name='active-status-group'
                value={showActive}
                onChange={e => setShowActive(e.target.value)}
              >
                <FormControlLabel value='active' control={<Radio />} label='Active' />
                <FormControlLabel value='inactive' control={<Radio />} label='Inactive' />
                <FormControlLabel value='all' control={<Radio />} label='All' />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              id='jurisdictionOptions'
              options={jurisdictionOptions || []}
              value={selectedJdx}
              size='small'
              getOptionLabel={option => option?.jdxName}
              onChange={(event, data) => setSelectedJdx(data)}
              renderInput={params => (
                <TextField {...params} label='Jurisdiction' placeholder='Jurisdiction' />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label='Filter by Last Name, First Name or Role'
              variant='outlined'
              fullWidth
              value={searchFilter}
              onChange={e => setSearchFilter(e.target.value)}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2, opacity: 1 }} />

        <ReportDataGrid
          loading={loading}
          listresults={filteredList}
          idcolumn='userSeq'
          columnsInput={columns}
          title='Users Found'
          casecount
          rowsperpage={10}
          maxHeight={'70vh'}
          // chatcontent={
          //   <Paper
          //     elevation={3}
          //     sx={{
          //       p: '10px',
          //       px: '20px',
          //       borderRadius: '20px',
          //       width: 'max-content',
          //     }}
          //   >
          //     <div style={{ textAlign: 'center' }}>
          //       I found <b>{filteredList?.length} </b> users. Details are listed below, you can
          //       export this list to CSV format.
          //     </div>
          //   </Paper>
          // }
        />
      </CardContent>
    </Card>
  );
}
